<template>
  <v-container
    class="notification-template-editor-preview__notifications_phone__notification"
  >
    <v-row
      class="notification-template-editor-preview__notifications_phone__notification-title"
    >
      <v-col class="d-flex align-center">
        <img :src="iconUrl" class="mr-2" />
        <span class="text-uppercase text-body-2">{{ headline }}</span>
      </v-col>
      <v-col class="d-flex justify-end">
        <span class="text-body-2">
          {{ $trans("push_now_indicator") }}
        </span>
      </v-col>
    </v-row>
    <v-row
      class="notification-template-editor-preview__notifications_phone__notification-subtitle mt-1"
      no-gutters
    >
      <v-col>
        <span class="font-weight-bold text-body-1">{{ title }}</span>
      </v-col>
    </v-row>
    <v-row
      class="notification-template-editor-preview__notifications_phone__notification-body"
      no-gutters
    >
      <v-col>
        <span class="text-body-2">{{ body }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
  name: "NotificationPreview",
  props: {
    icon: {
      type: String,
      validate: (value) => ["sms", "cd"].includes(value),
      default: "cd",
    },
    headline: {
      type: String,
      default: "Calendesk",
    },
    title: {
      type: String,
      default: "",
    },
    body: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      emailSubject: null,
      emailText: null,
    };
  },
  computed: {
    ...mapGetters({}),
    iconUrl() {
      if (this.icon === "cd") {
        return require("@/assets/cd-icon.png");
      }

      return require("@/assets/sms-icon.png");
    },
  },
  created() {},
  methods: {
    ...mapActions({}),
  },
});
</script>

<style lang="scss" scoped>
.notification-template-editor-preview__notifications_phone__notification {
  background-color: var(--v-cd_color_5-base);
  margin: 15px;
  border-radius: 10.75px;
  padding: 15px;
}

.notification-template-editor-preview__notifications_phone__notification-title
  span {
  color: var(--v-cd_color_6-base);
}
</style>
