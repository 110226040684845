var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"notification-template-editor-sidebar fill-height",attrs:{"tile":"","elevation":"5"}},[_c('v-container',[(_vm.editorModalSelectedRecord.email)?[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$trans("notification_template_editor_sidebar_email_title")))])])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"email-subject",attrs:{"outlined":"","rows":2,"label":_vm.$trans('email_subject'),"v-scroll":false,"no-resize":"","counter":"65","counter-value":_vm.counterValue},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('notification-variable-menu',{attrs:{"variables":_vm.getEmailSubjectVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition(
                    $event,
                    'email-subject',
                    'emailSubject'
                  )}}})]},proxy:true}],null,false,154707572),model:{value:(_vm.emailSubject),callback:function ($$v) {_vm.emailSubject=$$v},expression:"emailSubject"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tip-tap',{ref:"email-text",attrs:{"label":_vm.$trans('notification_template_editor_sidebar_text'),"placeholder":_vm.$trans('notification_template_editor_sidebar_text')},on:{"input":function($event){_vm.emailText = $event}},model:{value:(_vm.emailText),callback:function ($$v) {_vm.emailText=$$v},expression:"emailText"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('notification-variable-menu',{attrs:{"show-text":"","variables":_vm.getEmailVariables},on:{"select":function($event){return _vm.insertTextToHTMLTextarea($event, 'email-text')}}})],1)],1)]:_vm._e(),(_vm.editorModalSelectedRecord.sms)?[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$trans("notification_template_editor_sidebar_sms_title")))])])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[(
            _vm.getNonGsmCharacters(_vm.smsText) &&
            _vm.getNonGsmCharacters(_vm.smsText).length > 0
          )?_c('v-col',[_vm._v(" "+_vm._s(_vm.$trans("notification_contains_special_characters"))+": "+_vm._s(_vm.getNonGsmCharacters(_vm.smsText).join(", "))+" ")]):_vm._e(),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-textarea',{ref:"sms-text",attrs:{"outlined":"","rows":4,"label":_vm.$trans('notification_template_editor_sidebar_text'),"no-resize":"","hint":_vm.getSMSHint(_vm.smsText),"counter":"160","counter-value":_vm.counterValue,"persistent-hint":""},model:{value:(_vm.smsText),callback:function ($$v) {_vm.smsText=$$v},expression:"smsText"}})],1),_c('v-col',{staticClass:"pt-0"},[_c('notification-variable-menu',{attrs:{"show-text":"","variables":_vm.getAllVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition($event, 'sms-text', 'smsText')}}})],1)],1),_c('v-row',[_c('v-col',[_c('small',[_vm._v("*"+_vm._s(_vm.$trans("sms_number_of_messages_info")))])])],1)]:_vm._e(),(_vm.editorModalSelectedRecord.push)?[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{staticClass:"d-flex justify-space-between align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$trans("notification_template_editor_sidebar_push_title")))])])],1),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{ref:"push-subject",attrs:{"outlined":"","rows":2,"label":_vm.$trans('email_subject'),"no-resize":"","counter":"65","counter-value":_vm.counterValue},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('notification-variable-menu',{attrs:{"variables":_vm.getAllVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition(
                    $event,
                    'push-subject',
                    'pushSubject'
                  )}}})]},proxy:true}],null,false,3006876261),model:{value:(_vm.pushSubject),callback:function ($$v) {_vm.pushSubject=$$v},expression:"pushSubject"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12"}},[_c('v-textarea',{ref:"push-text",attrs:{"outlined":"","rows":4,"label":_vm.$trans('notification_template_editor_sidebar_text'),"no-resize":"","counter":"240","counter-value":_vm.counterValue},model:{value:(_vm.pushText),callback:function ($$v) {_vm.pushText=$$v},expression:"pushText"}})],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('notification-variable-menu',{attrs:{"show-text":"","variables":_vm.getAllVariables},on:{"select":function($event){return _vm.insertTextOnCursorPosition($event, 'push-text', 'pushText')}}})],1)],1)]:_vm._e(),_c('v-row',[_c('v-col',[_c('v-divider')],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center"},[_c('v-btn',{attrs:{"color":"danger","text":"","small":""},on:{"click":_vm.clearAllNotifications}},[_c('span',[_vm._v(" "+_vm._s(_vm.$trans("notification_template_editor_sidebar_restore_all"))+" ")])])],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }