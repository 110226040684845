<template>
  <v-data-table
    :items="data"
    :headers="headers"
    hide-default-footer
    hide-default-header
    class="notification-templates-table"
  >
    <template #[`item.name`]="{ item }">
      <div class="d-flex align-content-center align-center py-4">
        <div class="mr-4">
          <img
            :src="require(`@/assets/${item.category}-${item.type}-icon.png`)"
          />
        </div>
        <div>
          <span class="font-weight-medium">{{ item.display_name }}</span>
          <br />
          <small>{{ item.display_info }}</small>
        </div>
      </div>
    </template>
    <template #[`item.button`]="{ item }">
      <div class="py-4">
        <v-badge
          v-if="item.email && updatedAtForItemAndType(item, 'email')"
          color="green"
          overlap
          class="ma-1"
        >
          <template #badge>
            <v-icon x-small color="white">$check</v-icon>
          </template>
          <v-btn
            small
            color="blue"
            depressed
            dark
            @click="openEditor(item, 'email')"
          >
            {{ $trans("edit_email") }}
          </v-btn>
        </v-badge>
        <v-btn
          v-else-if="item.email"
          small
          color="blue"
          depressed
          dark
          class="ma-1"
          @click="openEditor(item, 'email')"
        >
          {{ $trans("edit_email") }}
        </v-btn>
        <v-badge
          v-if="item.sms && updatedAtForItemAndType(item, 'sms')"
          color="green"
          overlap
          bordered
          class="ma-1"
        >
          <template #badge>
            <v-icon x-small color="white"> $check</v-icon>
          </template>
          <v-btn
            class="ma-1"
            small
            depressed
            dark
            color="blue"
            @click="openEditor(item, 'sms')"
          >
            {{ $trans("edit_sms") }}
          </v-btn>
        </v-badge>
        <v-btn
          v-else-if="item.sms"
          small
          depressed
          dark
          color="blue"
          class="ma-1"
          @click="openEditor(item, 'sms')"
        >
          {{ $trans("edit_sms") }}
        </v-btn>
        <v-badge
          v-if="item.push && updatedAtForItemAndType(item, 'push')"
          color="green"
          overlap
          bordered
          class="ma-1"
        >
          <template #badge>
            <v-icon x-small color="white"> $check</v-icon>
          </template>
          <v-btn
            color="blue"
            small
            depressed
            dark
            @click="openEditor(item, 'push')"
          >
            {{ $trans("edit_push") }}
          </v-btn>
        </v-badge>
        <v-btn
          v-else-if="item.push"
          color="blue"
          small
          depressed
          dark
          class="ma-1"
          @click="openEditor(item, 'push')"
        >
          {{ $trans("edit_push") }}
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "NotificationTemplateDataTable",
  mixins: [sharedActions],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showEditor: false,
      headers: [
        {
          sortable: false,
          value: "name",
          align: "start",
        },
        {
          sortable: false,
          value: "button",
          align: "end",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      setEditorModalSelectedRecord: "notification/setEditorModalSelectedRecord",
      setEditorModalVisible: "notification/setEditorModalVisible",
    }),
    updatedAtForItemAndType(item, type) {
      if (item.templates) {
        const template = item.templates.find((tmp) => tmp.type.includes(type));

        if (template && template.updated_at) {
          return template.updated_at;
        }
      }

      return null;
    },
    openEditor(item, type) {
      pushEvent("openNotificationEditor");

      const clone = this.$helpers.cloneObject(item);
      clone.email = false;
      clone.sms = false;
      clone.push = false;
      clone[type] = true;

      this.setEditorModalSelectedRecord(clone);
      this.setEditorModalVisible(true);
    },
  },
};
</script>
<style lang="scss">
.notification-templates-table .v-data-table__mobile-row__cell {
  width: 100%;
}
</style>
